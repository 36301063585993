import { ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { CustomOverlayRef } from '@core/services/custom-overlay-ref';
import { ProfileModalsService } from '@features/profile/profile-modals.service';
import { ModalModule } from '@shared/modal/modal.component';
import { PostEventResponse } from '@shared/models/events.model';
import { ModalComponent } from '../../../shared/modal/modal.component';

@Component({
  selector: 'app-logout-info-modal',
  templateUrl: './logout-info-modal.component.html',
  styleUrls: ['./logout-info-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ModalComponent],
})
export class LogoutInfoModalComponent {
  constructor(
    public modalRef: CustomOverlayRef<PostEventResponse, void>,
    private profileModalsService: ProfileModalsService
  ) {}

  public signIn(): void {
    this.modalRef.close();
    this.profileModalsService.signIn();
  }
}

@NgModule({
  imports: [ModalModule, LogoutInfoModalComponent],
})
export class LogoutInfoModalModule {}
