import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { take } from 'rxjs/operators';
import { AnalyticsService } from '@core/services/analytics.service';
import { FacebookPixelService } from '@core/services/facebook-pixel.service';
import { environment } from '@env/environment';
import { CountriesService } from '@shared/services/countries.service';
// import { GeolocationService } from '@shared/services/geolocation.service';
import { FooterComponent } from '@core/components/footer/footer.component';
import { HeaderComponent } from '@core/components/header/header.component';
import { ToastModule } from '@shared/toast/toast.component';

@Component({
  selector: 'app-root',
  imports: [RouterOutlet, ToastModule, FooterComponent, HeaderComponent],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    // private geolocationService: GeolocationService,
    private analyticsService: AnalyticsService,
    private facebookPixelService: FacebookPixelService,
    private countriesService: CountriesService,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: {},
    @Inject(DOCUMENT) private document: Document
  ) {
    // TODO: Disable geolocation for now.
    // this.geolocationService.getLocation();
    this.countriesService.countries$.pipe(take(1)).subscribe();
  }

  public ngOnInit(): void {
    if (environment.production && isPlatformBrowser(this.platformId)) {
      this.analyticsService.initAnalytics();
      this.facebookPixelService.initPixel();
      this.initVideoPlayer();
    }
  }

  private initVideoPlayer(): void {
    const tag: HTMLScriptElement = this.renderer.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    this.renderer.appendChild(this.document.body, tag);
  }
}
