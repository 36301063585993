import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppRoutes } from '@core/router/routes';
import { slideToggle, slideToggleParent } from '@shared/animations/slide-toggle';
import { ClickOutsideModule } from '@shared/utils/click-outside.directive';

@Component({
  selector: 'app-mobile-navigation',
  templateUrl: './mobile-navigation.component.html',
  styleUrls: ['./mobile-navigation.component.scss'],
  imports: [RouterModule, ClickOutsideModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideToggleParent, slideToggle],
})
export class MobileNavigationComponent {
  @Output() closeMenu: EventEmitter<null> = new EventEmitter();

  @Input() routes: [AppRoutes, string][];

  @Input() isAuthorized: boolean;

  @Input() public wishlistQuantity: number;

  public open: boolean;

  public AppRoutes: typeof AppRoutes = AppRoutes;

  public get isOpen(): boolean {
    return this.open;
  }

  @Input()
  public set isOpen(open: boolean) {
    this.open = open;
  }

  public closeMenuHandler(): void {
    this.closeMenu.next(null);
  }
}
