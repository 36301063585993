import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { DestroySubject } from '@shared/utils/destroy-subject';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService extends DestroySubject {
  constructor(private gtmService: GoogleTagManagerService, private router: Router) {
    super();
  }

  public async initAnalytics(): Promise<void> {
    this.router.events.pipe(takeUntil(this.destroy$)).forEach((item: Event | RouterEvent) => {
      if (item instanceof NavigationEnd) {
        const gtmTag: object = {
          event: 'page',
          pageName: item.url,
        };

        this.gtmService.pushTag(gtmTag);
      }
    });
  }
}
