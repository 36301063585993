import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AccountRoute, AppRoutes } from '@core/router/routes';
import { AccountDetails } from '@features/auth/auth.models';
import { AuthService } from '@features/auth/auth.service';
import { fadeInOutTop } from '@shared/animations/fade-in-out-top';
import { iif, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { UpcomingEventService } from '../events/upcoming-event.service';
import { ProfileModalsService } from '../profile-modals.service';
import { ProfileService } from '../profile.service';
import { MenuNewEventService } from './menu-new-event.service';
import { MenuNewProductService } from './menu-new-product.service';
import { ReserveProductsModalComponent } from './reserve-products-modal/reserve-products-modal.component';
import { ClickOutsideModule } from '@shared/utils/click-outside.directive';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [fadeInOutTop],
  imports: [NgIf, AsyncPipe, RouterModule, ClickOutsideModule, ReserveProductsModalComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent {
  public isDropdownOpen: boolean = false;

  public isAuthorized$: Observable<boolean> = this.authService.isAuthorized$;

  public accountDetails$: Observable<AccountDetails> = this.profileService.accountDetails$;

  public upcomingEvents$: Observable<number> = this.isAuthorized$.pipe(
    switchMap((isAuthorized) =>
      iif(() => isAuthorized, this.upcomingEventService.data$.pipe(map((events) => events.length)), of(null))
    ),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public authorizedRoutes: [string[], string][] = [
    [['/', AppRoutes.Account], ' Account Settings'],
    [['/', AppRoutes.Account, AccountRoute.Events], 'My Events'],
  ];

  constructor(
    public newProduct$: MenuNewProductService,
    public newEvent$: MenuNewEventService,
    private authService: AuthService,
    private profileService: ProfileService,
    private profileModalsService: ProfileModalsService,
    private upcomingEventService: UpcomingEventService
  ) {}

  public toggleDropdown(event: Event): void {
    event.stopPropagation();

    this.isDropdownOpen = !this.isDropdownOpen;
  }

  public closeDropdown(): void {
    this.isDropdownOpen = false;
  }

  public registerCharity(): void {
    this.isDropdownOpen = false;
    this.profileModalsService.registerCharity();
  }

  public signIn(): void {
    this.isDropdownOpen = false;
    this.profileModalsService.signIn();
  }

  public logOut(): void {
    this.isDropdownOpen = false;
    this.authService.logout();
  }
}
